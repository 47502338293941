// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as UserListCloudRackTabs from "./UserListCloudRackTabs.res.js";
import * as UserListCloudRackScss from "./UserListCloudRack.scss";

var css = UserListCloudRackScss;

function UserListCloudRack(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("h1", {
                            children: "Cloud Rack Users",
                            className: css.titleMain
                          }),
                      JsxRuntime.jsx(UserListCloudRackTabs.make, {})
                    ],
                    className: css.wrapper
                  }),
              className: css.layout
            });
}

var make = UserListCloudRack;

var $$default = UserListCloudRack;

export {
  css ,
  make ,
  $$default as default,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserListCloudRackTabsScss from "./UserListCloudRackTabs.scss";
import * as UserListCloudRackTabZenlayer from "./UserListCloudRackTabZenlayer.res.js";
import * as UserListCloudRackTabControlPlane from "./UserListCloudRackTabControlPlane.res.js";

var css = UserListCloudRackTabsScss;

function UserListCloudRackTabs(props) {
  var match = React.useState(function () {
        return "ControlPlane";
      });
  var setActiveTab = match[1];
  var activeTab = match[0];
  var tmp;
  tmp = activeTab === "ControlPlane" ? css.tabActive : "";
  var tmp$1;
  tmp$1 = activeTab === "ControlPlane" ? "" : css.tabActive;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Control Plane",
                              className: Cx.cx([
                                    css.tab,
                                    tmp
                                  ]),
                              onClick: (function (param) {
                                  setActiveTab(function (param) {
                                        return "ControlPlane";
                                      });
                                })
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Zenlayer",
                              className: Cx.cx([
                                    css.tab,
                                    tmp$1
                                  ]),
                              onClick: (function (param) {
                                  setActiveTab(function (param) {
                                        return "Zenlayer";
                                      });
                                })
                            })
                      ],
                      className: css.tabs
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        activeTab === "ControlPlane" ? JsxRuntime.jsx(UserListCloudRackTabControlPlane.make, {}) : null,
                        activeTab === "Zenlayer" ? JsxRuntime.jsx(UserListCloudRackTabZenlayer.make, {}) : null
                      ]
                    })
              ]
            });
}

var make = UserListCloudRackTabs;

export {
  css ,
  make ,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserListCloudRackTableRowScss from "./UserListCloudRackTableRow.scss";

var css = UserListCloudRackTableRowScss;

function UserListCloudRackTableRow(props) {
  var isHighlighted = props.isHighlighted;
  var isHeader = props.isHeader;
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([
                    css.tableRow,
                    isHeader !== undefined && isHeader ? css.tableRowHeader : "",
                    isHighlighted !== undefined && isHighlighted ? css.tableRowHighlighted : ""
                  ]),
              style: {
                gridTemplateColumns: props.gridTemplateColumns
              }
            });
}

var make = UserListCloudRackTableRow;

export {
  css ,
  make ,
}
/* css Not a pure module */

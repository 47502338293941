// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserListCloudRackTableCellScss from "./UserListCloudRackTableCell.scss";

var css = UserListCloudRackTableCellScss;

function UserListCloudRackTableCell(props) {
  var onClick = props.onClick;
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([
                    css.tableCell,
                    onClick !== undefined ? css.tableCellClickable : ""
                  ]),
              onClick: (function ($$event) {
                  if (onClick !== undefined) {
                    return onClick($$event);
                  }
                  
                })
            });
}

var make = UserListCloudRackTableCell;

export {
  css ,
  make ,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Select from "../../../styleguide/forms/Select/Select.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as IconSorting from "../../../styleguide/icons/IconSorting.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserListCloudRackTableRow from "./primitives/UserListCloudRackTableRow.res.js";
import * as UserListCloudRackTableCell from "./primitives/UserListCloudRackTableCell.res.js";
import * as UserListCloudRackSearchAndExport from "./UserListCloudRackSearchAndExport.res.js";
import * as UserListCloudRackZenlayerReducer from "./UserListCloudRackZenlayerReducer.res.js";
import * as UserListCloudRackTabControlPlaneScss from "./UserListCloudRackTabControlPlane.scss";

var css = UserListCloudRackTabControlPlaneScss;

var initialState = {
  currentPage: 1,
  totalPages: 1,
  currentUsers: undefined,
  filterByStatus: undefined,
  filterByHistory: undefined,
  searchTerm: undefined,
  sortBy: {
    NAME: "Created",
    VAL: "Asc"
  }
};

function UserListCloudRackTabZenlayer(props) {
  var match = Hooks.useReducer(initialState, UserListCloudRackZenlayerReducer.reducer);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchUsers");
        }), []);
  var gridTemplateColumns = "140px 200px 1fr 120px 120px";
  var searchTerm = state.searchTerm;
  var filter = state.filterByStatus;
  var filter$1 = state.filterByHistory;
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var currentUsers = state.currentUsers;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(UserListCloudRackSearchAndExport.make, {
                            searchComponent: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(TextField.make, {
                                          id: "search",
                                          value: searchTerm !== undefined ? searchTerm : "",
                                          placeholder: "Search by name, email",
                                          className: css.textField,
                                          onChange: (function ($$event) {
                                              dispatch({
                                                    TAG: "Search",
                                                    _0: $$event.target.value
                                                  });
                                            })
                                        }),
                                    JsxRuntime.jsxs(Select.make, {
                                          id: "status",
                                          value: filter !== undefined ? UserListCloudRackZenlayerReducer.statusFilterToJs(filter) : "",
                                          onChange: (function ($$event) {
                                              var value = $$event.target.value;
                                              dispatch({
                                                    TAG: "FilterByStatus",
                                                    _0: value === "" ? undefined : value
                                                  });
                                            }),
                                          children: [
                                            JsxRuntime.jsx(Select.DefaultOption.make, {
                                                  selectable: true,
                                                  children: "Status"
                                                }),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: UserListCloudRackZenlayerReducer.statusFilterToJs("Active"),
                                                  children: "Active"
                                                }),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: UserListCloudRackZenlayerReducer.statusFilterToJs("Pending"),
                                                  children: "Pending"
                                                })
                                          ]
                                        }),
                                    JsxRuntime.jsxs(Select.make, {
                                          id: "history",
                                          value: filter$1 !== undefined ? UserListCloudRackZenlayerReducer.historyFilterToJs(filter$1) : "",
                                          onChange: (function ($$event) {
                                              var value = $$event.target.value;
                                              dispatch({
                                                    TAG: "FilterByHistory",
                                                    _0: value === "" ? undefined : value
                                                  });
                                            }),
                                          children: [
                                            JsxRuntime.jsx(Select.DefaultOption.make, {
                                                  selectable: true,
                                                  children: "History"
                                                }),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: UserListCloudRackZenlayerReducer.historyFilterToJs("New"),
                                                  children: "New"
                                                }),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: UserListCloudRackZenlayerReducer.historyFilterToJs("Existing"),
                                                  children: "Existing"
                                                })
                                          ]
                                        })
                                  ],
                                  className: css.searchRow
                                }),
                            onExportButtonClick: (function (param) {
                                dispatch("Export");
                              })
                          }),
                      className: css.searchAndExportRow
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs(UserListCloudRackTableRow.make, {
                              children: [
                                JsxRuntime.jsxs(UserListCloudRackTableCell.make, {
                                      children: [
                                        "Created",
                                        JsxRuntime.jsx(IconSorting.make, {
                                              size: "XXS",
                                              direction: typeof match$1 === "object" && match$1.NAME === "Created" ? match$1.VAL : undefined
                                            })
                                      ],
                                      onClick: (function (param) {
                                          dispatch("SortByCreated");
                                        })
                                    }),
                                JsxRuntime.jsxs(UserListCloudRackTableCell.make, {
                                      children: [
                                        "Name",
                                        JsxRuntime.jsx(IconSorting.make, {
                                              size: "XXS",
                                              direction: typeof match$2 === "object" && match$2.NAME === "Name" ? match$2.VAL : undefined
                                            })
                                      ],
                                      onClick: (function (param) {
                                          dispatch("SortByName");
                                        })
                                    }),
                                JsxRuntime.jsx(UserListCloudRackTableCell.make, {
                                      children: "Email"
                                    }),
                                JsxRuntime.jsx(UserListCloudRackTableCell.make, {
                                      children: "Status"
                                    }),
                                JsxRuntime.jsx(UserListCloudRackTableCell.make, {
                                      children: "History"
                                    })
                              ],
                              gridTemplateColumns: gridTemplateColumns,
                              isHeader: true
                            }),
                        currentUsers !== undefined ? Belt_Array.mapWithIndex(currentUsers, (function (index, row) {
                                  var created_at = row.created_at;
                                  return JsxRuntime.jsxs(UserListCloudRackTableRow.make, {
                                              children: [
                                                JsxRuntime.jsx(UserListCloudRackTableCell.make, {
                                                      children: created_at !== undefined ? created_at : null
                                                    }),
                                                JsxRuntime.jsx(UserListCloudRackTableCell.make, {
                                                      children: JsxRuntime.jsx("strong", {
                                                            children: row.name
                                                          })
                                                    }),
                                                JsxRuntime.jsx(UserListCloudRackTableCell.make, {
                                                      children: row.email
                                                    }),
                                                JsxRuntime.jsx(UserListCloudRackTableCell.make, {
                                                      children: row.status
                                                    }),
                                                JsxRuntime.jsx(UserListCloudRackTableCell.make, {
                                                      children: row.history
                                                    })
                                              ],
                                              gridTemplateColumns: gridTemplateColumns,
                                              isHighlighted: index % 2 !== 0
                                            }, row.email);
                                })) : JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Spinner.make, {
                                      size: "XL",
                                      color: "Teal"
                                    }),
                                className: css.tableBodyMinHeight
                              })
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Pagination.make, {
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            onPageClick: (function (pageNumber) {
                                dispatch({
                                      TAG: "ChangePage",
                                      _0: pageNumber
                                    });
                              })
                          }),
                      className: css.pagination
                    })
              ]
            });
}

var make = UserListCloudRackTabZenlayer;

export {
  css ,
  initialState ,
  make ,
}
/* css Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as $$Promise from "../../../bindings/Promise.res.js";

var _map = {"Active":"Active","Pending":"Pending"};

function statusFilterToJs(param) {
  return param;
}

function statusFilterFromJs(param) {
  return _map[param];
}

var _map$1 = {"New":"New","Existing":"Existing"};

function historyFilterToJs(param) {
  return param;
}

function historyFilterFromJs(param) {
  return _map$1[param];
}

function reducer(state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "FetchUsers" :
          return {
                  TAG: "SideEffects",
                  _0: (function (param) {
                      var dispatch = param.dispatch;
                      var state = param.state;
                      $$Promise.wait(Api.fetchCloudRackZenlayerUsersList(state.currentPage, state.filterByStatus, state.filterByHistory, state.searchTerm, state.sortBy, false), (function (x) {
                              if (x.TAG === "Ok") {
                                return dispatch({
                                            TAG: "FetchUsersSucceed",
                                            _0: x._0
                                          });
                              }
                              console.error(x._0);
                            }));
                    })
                };
      case "SortByCreated" :
          var match = state.sortBy;
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    currentPage: state.currentPage,
                    totalPages: state.totalPages,
                    currentUsers: state.currentUsers,
                    filterByStatus: state.filterByStatus,
                    filterByHistory: state.filterByHistory,
                    searchTerm: state.searchTerm,
                    sortBy: typeof match === "object" && match.NAME === "Created" && match.VAL !== "Desc" ? ({
                          NAME: "Created",
                          VAL: "Desc"
                        }) : ({
                          NAME: "Created",
                          VAL: "Asc"
                        })
                  },
                  _1: (function (param) {
                      param.dispatch("FetchUsers");
                    })
                };
      case "SortByName" :
          var match$1 = state.sortBy;
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    currentPage: state.currentPage,
                    totalPages: state.totalPages,
                    currentUsers: state.currentUsers,
                    filterByStatus: state.filterByStatus,
                    filterByHistory: state.filterByHistory,
                    searchTerm: state.searchTerm,
                    sortBy: typeof match$1 === "object" && match$1.NAME === "Name" && match$1.VAL !== "Desc" ? ({
                          NAME: "Name",
                          VAL: "Desc"
                        }) : ({
                          NAME: "Name",
                          VAL: "Asc"
                        })
                  },
                  _1: (function (param) {
                      param.dispatch("FetchUsers");
                    })
                };
      case "Export" :
          return {
                  TAG: "SideEffects",
                  _0: (function (param) {
                      var dispatch = param.dispatch;
                      var state = param.state;
                      $$Promise.wait(Api.fetchCloudRackZenlayerUsersList(state.currentPage, state.filterByStatus, state.filterByHistory, state.searchTerm, state.sortBy, true), (function (x) {
                              if (x.TAG === "Ok") {
                                return dispatch({
                                            TAG: "FetchUsersSucceed",
                                            _0: x._0
                                          });
                              }
                              console.error(x._0);
                            }));
                    })
                };
      
    }
  } else {
    switch (action.TAG) {
      case "FetchUsersSucceed" :
          var res = action._0;
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    currentPage: state.currentPage,
                    totalPages: Js_math.ceil(res.totalCount / 50.0),
                    currentUsers: res.usersList,
                    filterByStatus: state.filterByStatus,
                    filterByHistory: state.filterByHistory,
                    searchTerm: state.searchTerm,
                    sortBy: state.sortBy
                  },
                  _1: (function (param) {
                      
                    })
                };
      case "Search" :
          var searchTerm = action._0;
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    currentPage: state.currentPage,
                    totalPages: state.totalPages,
                    currentUsers: state.currentUsers,
                    filterByStatus: state.filterByStatus,
                    filterByHistory: state.filterByHistory,
                    searchTerm: searchTerm === "" ? undefined : searchTerm,
                    sortBy: state.sortBy
                  },
                  _1: (function (param) {
                      param.dispatch("FetchUsers");
                    })
                };
      case "FilterByStatus" :
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    currentPage: state.currentPage,
                    totalPages: state.totalPages,
                    currentUsers: state.currentUsers,
                    filterByStatus: action._0,
                    filterByHistory: state.filterByHistory,
                    searchTerm: state.searchTerm,
                    sortBy: state.sortBy
                  },
                  _1: (function (param) {
                      param.dispatch("FetchUsers");
                    })
                };
      case "FilterByHistory" :
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    currentPage: state.currentPage,
                    totalPages: state.totalPages,
                    currentUsers: state.currentUsers,
                    filterByStatus: state.filterByStatus,
                    filterByHistory: action._0,
                    searchTerm: state.searchTerm,
                    sortBy: state.sortBy
                  },
                  _1: (function (param) {
                      param.dispatch("FetchUsers");
                    })
                };
      case "ChangePage" :
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    currentPage: action._0,
                    totalPages: state.totalPages,
                    currentUsers: state.currentUsers,
                    filterByStatus: state.filterByStatus,
                    filterByHistory: state.filterByHistory,
                    searchTerm: state.searchTerm,
                    sortBy: state.sortBy
                  },
                  _1: (function (param) {
                      param.dispatch("FetchUsers");
                    })
                };
      
    }
  }
}

var rows_per_page = 50.0;

export {
  statusFilterToJs ,
  statusFilterFromJs ,
  historyFilterToJs ,
  historyFilterFromJs ,
  rows_per_page ,
  reducer ,
}
/* Api Not a pure module */

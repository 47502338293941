import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { fromJs } from '../../../../rescript/models/User.res.js';

import UserListCloudRack from './UserListCloudRack.res.js';

const mapStateToProps = state => ({
  userData: fromJs(state.user),
});

const UserListCloudRackContainer = connect(mapStateToProps)(UserListCloudRack);

export default props => (
  <ReduxProvider {...props}>
    <UserListCloudRackContainer />
  </ReduxProvider>
);
